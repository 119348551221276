html * {
    font-family: Lato !important;
}
::selection {
    background: #34ce72 !important;
}
::-moz-selection {
    background: #34ce72 !important;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

html .ant-popover-arrow {
    display: none;
}

html .ant-popover-placement-left, html .ant-popover-placement-leftTop, html .ant-popover-placement-leftBottom {
    padding-right: 0;
}

html .ant-popover-inner {
    border-radius: .5rem;
}


.item-fadeout {
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
    background-color: #fff
}

.scrollbar::-webkit-scrollbar-track:hover {
    background-color: #888;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar:hover {
    height: 10px;
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #babac0;
    border-radius: 5px;
    height: 10px;
    width: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
}

/*  Select dropdown */
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f2f6fa;
}

.ant-select-dropdown .ant-select-item {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.43;
    color: #061c2e;
    text-transform: capitalize;
}

.ant-tooltip-inner {
    padding: 12px 16px !important;
    background-color: #324759 !important;
    font-family: Lato !important;
    font-size: 12px;
    line-height: 1.33;
    border-radius: 6px !important;
}

.left-floated {
   float: left;
}

.hidden {
    display: none !important;
}
