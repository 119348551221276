.fc {
    font-family: Lato;
}

.fc-scrollgrid {
    background-color: #ffffff;
    border: none !important;
    border-radius: 8px;
}

.fc-header-toolbar.fc-toolbar .fc-toolbar-chunk {
    display: flex;
    justify-content: space-between;
}

.fc-daygrid-body-unbalanced, .fc-scrollgrid-sync-table {
    width: 100% !important;
}

.fc-toolbar-title {
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #061c2e;
}

#calendar-page .fc-toolbar-title {
    min-width: 185px;
}

#calendar-page .fc-h-event {
    border: none;
    background-color: transparent;
}
#calendar-page .fc-day-today .fc-daygrid-day-number {
    color: #34ce72;
}
#calendar-page .fc-day-today{
    position: relative;
}
#calendar-page .fc-day-today:before{
    content: '';
    background: #34ce72;
    width: calc(100% - 20px);
    top: 0;
    left: 10px;
    position: absolute;
    height: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
#calendar-page .fc-theme-standard td, #calendar-page .fc-theme-standard th {
    border-color: #dae5ec;
    background-color: transparent;
}

#calendar-page .fc-daygrid-day-bottom {
    padding: 0;
}

.fc-icon-chevron-right {
    background: url("../../icons/arrow-right.svg") center no-repeat;
}

.fc-icon-chevron-right:before, .fc-icon-chevron-left:before {
    content: '' !important;
}

.fc-icon-chevron-left {
    background: url("../../icons/arrow-left.svg") center no-repeat;
}

.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
    background: #ffffff;
    outline: none;
}

#calendar-page .fc-direction-ltr .fc-daygrid-event.fc-event-end, #calendar-page .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    display: block;
    margin: 0;
    padding: 0;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    margin-left: 0.5rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fc-icon {
    font-family: Lato !important;
    line-height: 1.1rem !important;

}

.fc-icon-chevron-left:before {
    content: '<';
    color: #627d95;
}

.fc-icon-chevron-right:before {
    content: '>';
    color: #627d95;
}

.fc .fc-button-primary {
    background: #ffffff;
    border-color: #fff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fc .fc-button-primary:hover {
    background: #ffffff;
    border-color: #fff;
}

.fc-header-toolbar.fc-toolbar .fc-toolbar-chunk .fc-button-group {
    margin-left: 1rem;
}

.fc-col-header-cell-cushion {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.96px;
    text-align: right;
    color: #627d95;
    text-transform: uppercase;
    padding: 2rem 1rem 1rem 1rem !important;
}

.fc th {
    text-align: right;
    border-right: none !important;
    border-left: none !important;
}

.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
    background: inherit;
}

.fc .fc-button.fc-unconfirmedEventsButton-button {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #eb5757;

    border: none;
    cursor: pointer;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display: flex;
    align-items: center;
}

th.fc-col-header-cell.fc-day.fc-day-mon {
    border-right: none !important;
    border-left: none !important;
}

.fc .fc-view-harness {
    /*height: calc(100vh - 130px) !important;*/
    height: auto !important;
}

.fc .fc-daygrid-day-number {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: #061c2e;
    padding-right: 10px;
}

.fc-col-header-cell-cushion {
    cursor: default;
}

.fc-col-header-cell-cushion:hover {
    color: #627d95;
}

.fc-daygrid-day-number {
    cursor: default;
}

#calendar-page .fc .fc-scroller-liquid-absolute {
    position: relative;
}

#calendar-page .fc .fc-view-harness-active > .fc-view {
    position: relative;
}
