.fc {
  font-family: Lato;
}

.fc-scrollgrid {
  background-color: #ffffff;
  border: none !important;
  border-radius: 8px;
}

.calendar-widget .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  align-items: center;
}

.calendar-widget .fc-toolbar-title {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  color: #061c2e;
  text-transform: uppercase;
}

.calendar-widget .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0;
}

.calendar-widget  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-left: 0.5rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0;
}

.fc-icon {
  font-family: Lato !important;
  line-height: 1.1rem !important;

}

.fc-icon-chevron-left:before {
  content: '<';
  color: #627d95;
}

.fc-icon-chevron-right:before {
  content: '>';
  color: #627d95;
}

.fc .fc-button-primary {
  background: #ffffff;
  border-color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fc .fc-button-primary:hover {
  background: #ffffff;
  border-color: #fff;
}

.fc-header-toolbar.fc-toolbar .fc-toolbar-chunk .fc-button-group {
  margin-left: 1rem;
}

.calendar-widget .fc-daygrid-day-frame {
  position: relative;
  cursor: pointer;
  padding-bottom: 2px;
}

.calendar-widget .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 1px;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  position: absolute;
  max-height: 10px;
}

.calendar-widget .fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.calendar-widget thead tbody {
  border-bottom: 1px solid #dae5ec;
}

.calendar-widget .fc .fc-scrollgrid-section-liquid {
  height: 1px;
}

.calendar-widget .fc-daygrid-event-harness:nth-child(n+5) {
  visibility: hidden;
}

.calendar-widget .fc .fc-daygrid-day-number {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  padding-bottom: 1px !important;
  padding-right: 4px;
  cursor: pointer;
}

.calendar-widget .fc-daygrid-day:hover .fc-daygrid-day-number {
  color: #fff;
}

.calendar-widget .fc-daygrid-day:hover .fc-daygrid-event {
  background-color: #fff;
}


.calendar-widget .fc .fc-day-today .fc-daygrid-day-number {
  color: #fff;
}

.calendar-widget .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px;
}

.calendar-widget .fc-col-header-cell-cushion {
  padding: 2px 0 1px !important;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #627d95;
}

.calendar-widget .fc .fc-daygrid-day.fc-day-today {
  border-radius: 8px;
  background-color: #627d95;
}

.calendar-widget .fc-daygrid-day {
  cursor: pointer;
  position: relative;
}

.calendar-widget .fc-daygrid-day:hover {
  border-radius: 8px;
  background-color: #34ce72;
  color: #fff;
}

.calendar-widget .fc-theme-standard td, .fc-theme-standard th {
  border: none;
}

.calendar-widget .fc .fc-daygrid-day-top {
  justify-content: center;
}

.fc-col-header-cell-cushion {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: right;
  color: #627d95;
  text-transform: uppercase;
  padding: 2rem 1rem 1rem 1rem !important;
}

.fc th {
  text-align: right;
  border-right: none !important;
  border-left: none !important;
}

.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: inherit;
}

.fc .fc-button.fc-unconfirmedEventsButton-button{
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eb5757;

  border: none;
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
}

th.fc-col-header-cell.fc-day.fc-day-mon {
  border-right: none !important;
  border-left: none !important;
}

.calendar-widget .fc .fc-view-harness {
  height: 192px !important;
}

.calendar-widget .fc-daygrid-day-bottom {
  display: none !important;
}

.calendar-widget .fc-daygrid-event {
  width: 4px;
  height: 4px;
  flex-grow: 0;
  border-radius: 4px;
  background-color: #34ce72;
  margin-right: 2px !important
}

.calendar-widget .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0 !important;
  margin-right: 2px !important;
  margin-bottom: 2px !important;
}

.fc .fc-daygrid-day-number {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #061c2e;
}


.fc-col-header-cell-cushion {
  cursor: default;
}

.fc-col-header-cell-cushion:hover {
  color: #627d95;
}

.fc-daygrid-day-number {
  cursor: default;
}
.fc .fc-scroller-harness{
  overflow: initial;
}
.fc .fc-scroller-liquid-absolute{
  overflow: initial !important;
}

